import * as React from "react"
import { ImgDestra, ImgSinistra, ImgCentro, ImgLogo, Img, Citazione, IndiceLuoghi, PaginaTesto, ChiSono, ChiSiamo, A, FbPage } from "../../../components/componentiPagine"

export default function Render() {
	return <PaginaTesto lang="it" name="corsica1831">
<h1>La fuga e l’esilio</h1>
<p>In seguito ad una rocambolesca fuga dalla città di Parma, Antonio si rifugia nella villa della spettabile famiglia Mariotti, la quale più tardi, una volta calmate le acque, lo aiuta ad abbandonare il territorio ducale attraverso il Passo della Cisa, in Appennino.</p>
<ImgCentro href="https://it.wikipedia.org/wiki/Passo_della_Cisa" nome="cisa.PNG" alt="Passo della Cisa" didascalia="Passo della Cisa - Berceto (PR)"/>
<p>Una volta raggiunta Pontremoli e poi Genova, si imbarca insieme alla famiglia di Jacopo Sanvitale (altro celebre esiliato), sul vaporetto Sully che li conduce a Marsiglia.</p>
<ImgCentro nome="marsiglia.PNG" alt="marsiglia" didascalia="Notre-Dame-de-la-Garde, Marsiglia"/>
<p>Nello stesso anno decide però di trovare rifugio in Corsica, dove viene impiegato come precettore presso una ricca famiglia di Bastia. Si tratta di un periodo abbastanza sereno per Antonio, il quale però nell’estate del 1833 viene allontanato dall’isola insieme agli altri profughi italiani per il timore che possano pianificare sbarchi e insurrezioni sulle coste toscane e liguri.</p>
<p>Durante il periodo corso si avvicina agli ideali di Giuseppe Mazzini e della “Giovane Italia” e, in particolare, intrattiene un rapporto epistolare con Luigi Amedeo Melegari, un insegnante di Reggio Emilia collaboratore di Mazzini.<br />
Come usa tra gli affiliati dell’associazione, è chiamato a scegliersi un nome di battaglia: il nome scelto da Antonio è, in omaggio a un’isola dell’Arcipelago campano, “Procida”.</p>
<ImgCentro nome="corsica.PNG" alt="Bastia" didascalia="Bastia, Corsica"/>
<h2>Link per approfondire</h2>
<p><A href="https://www.treccani.it/enciclopedia/giovine-italia/">“Giovane Italia”</A></p>
<p><A href="https://www.treccani.it/enciclopedia/giuseppe-mazzini_%28Dizionario-Biografico%29/">“Giuseppe Mazzini”</A></p>
<p><A href="https://www.treccani.it/enciclopedia/jacopo-sanvitale_(Dizionario-Biografico)/">“Jacopo Sanvitale”</A></p>
<p><A href="https://www.treccani.it/enciclopedia/luigi-amedeo-melegari_%28Dizionario-Biografico%29/">“Luigi Amedeo Melegari”</A></p>

	</PaginaTesto>
}